import React from "react";
import { Button, Icon } from "@shopify/polaris";
import { CirclePlusMinor } from "@shopify/polaris-icons";

export const RepeaterAddButton = ({ label, disabled, onClick }) => {
  return (
    <Button
      plain
      icon={<Icon source={CirclePlusMinor} color="base" />}
      onClick={onClick}
      disabled={disabled}
      accessibilityLabel={label}
    >
      {label}
    </Button>
  );
};
