import { Text, View, StyleSheet } from "@react-pdf/renderer";

// Component imports
import PreviewRichText from "./PreviewRichText";

const styles = StyleSheet.create({
  bold: { fontWeight: "bold" },
  repeaterDateLocation: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    color: "#575757",
    fontSize: "10.64px",
  },
  repeaterMainLine: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "1.5mm",
    color: "#404040",
    fontSize: "11.97px",
    textTransform: "uppercase",
    paddingBottom: "1mm",
  },
  repeaterDescriptionDivider: {
    marginTop: "0",
    marginBottom: "3.44mm",
    borderTop: "2px solid #979797",
    borderBottom: "none",
  },
  sectionRichContentWithMargin: {
    fontSize: "11.97px",
    marginBottom: "4mm",
  },
});

export const DateAndLocation = ({ value }) => {
  return value ? (
    <View style={styles.repeaterDateLocation}>
      <Text>{value}</Text>
    </View>
  ) : null;
};

export const MainLine = ({ primary, secondary }) => {
  return primary || secondary ? (
    <View>
      <View style={styles.repeaterMainLine}>
        {primary && <Text style={styles.bold}>{primary}</Text>}
        {secondary && <Text>{secondary}</Text>}
      </View>
    </View>
  ) : null;
};

export const RepeaterRichText = ({ value, includeSeparator }) => {
  return value && value !== `<p class="editor-paragraph"><br></p>` ? (
    <View>
      <View style={styles.repeaterDescriptionDivider}></View>
      <View style={styles.sectionRichContentWithMargin}>
        <PreviewRichText html={value} />
      </View>
    </View>
  ) : includeSeparator ? (
    <View style={styles.repeaterDescriptionDivider}></View>
  ) : null;
};
