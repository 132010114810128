import React from "react";
import moment from "moment";
import "moment/locale/bg";
import "moment/locale/hr";
import "moment/locale/cs";
import "moment/locale/da";
import "moment/locale/nl";
import "moment/locale/et";
import "moment/locale/fi";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/el";
import "moment/locale/hu";
import "moment/locale/is";
import "moment/locale/ga";
import "moment/locale/it";
import "moment/locale/lv";
import "moment/locale/lt";
import "moment/locale/mk";
import "moment/locale/mt";
import "moment/locale/nn";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/ro";
import "moment/locale/sr";
import "moment/locale/sk";
import "moment/locale/sl";
import "moment/locale/es";
import "moment/locale/sv";
import "moment/locale/tr";
import "moment/locale/uk";
import "moment/locale/me";

import { FormLayout, Text, Select } from "@shopify/polaris";
// Hook imports
import { useTranslation } from "../../hooks/useTranslation";
// State imports
import { useMainStore } from "../../stores/useMainStore";
// Util imports
import profileLanguages from "../../utils/codifications/profileLanguages.json";
import dateFormats from "../../utils/codifications/dateFormat.json";

export const General = () => {
  // State
  const translations = useTranslation();
  const { profile, actions: mainActions } = useMainStore();
  const { setNestedValue } = mainActions;
  if (profile.laguage === "no") {
    moment.locale("nn");
  } else {
    moment.locale(profile.language);
  }
  return (
    <FormLayout>
      <Select
        label={translations["global.select-cv-language"]}
        options={profileLanguages.map((profileLanguage) => ({
          label: translations[`global.dropDownLanguages.${profileLanguage}`],
          value: profileLanguage,
        }))}
        onChange={(value) => {
          setNestedValue("profile.language", value);
          setNestedValue(
            "profile.preference.dateFormat",
            dateFormats[value][0]
          );
        }}
        value={profile.language}
      />
      <Select
        label={translations["global.date-format"]}
        options={dateFormats[profile.language].map((dateFormat) => ({
          label: moment(new Date(2019, 3, 23)).format(dateFormat),
          value: dateFormat,
        }))}
        onChange={(value) =>
          setNestedValue("profile.preference.dateFormat", value)
        }
        value={profile.preference.dateFormat}
      />
    </FormLayout>
  );
};
