import React from "react";
import {
  FormLayout,
  Text,
  TextField,
  LegacyStack,
  Select,
} from "@shopify/polaris";
// Hook imports
import { useTranslation } from "../../hooks/useTranslation";
// State imports
import { useMainStore } from "../../stores/useMainStore";
// Component imports
import {
  AutocompletePhoneField,
  DatePickerField,
  LexiField,
  RepeaterInputField,
} from "../fields";
// Util imports
import sexes from "../../utils/codifications/sexes.json";

export const ContactDetails = () => {
  // State
  const translations = useTranslation();
  const { profile, actions: mainActions } = useMainStore();
  const { setNestedValue } = mainActions;
  return (
    <FormLayout>
      <RepeaterInputField
        label={translations["profile.personal-information.label.email"]}
        mainField="profile.personalInformation.emails"
        type="email"
      />
      <RepeaterInputField
        label={translations["profile.personal-information.label.phone"]}
        mainField="profile.personalInformation.phones"
        type="phone"
      />
      {/* <AutocompletePhoneField label={translations["global.dropdown-select"]} /> */}
    </FormLayout>
  );
};
