import React, { useState, useEffect } from "react";
import { LegacyStack, Select, Text } from "@shopify/polaris";
import { YEARS, MONTHS, MONTHS_WITH_30_DAYS } from "../../utils/constants";
import { useMainStore } from "../../stores/useMainStore";

export const DatePickerField = ({ label, field }) => {
  // State
  const state = useMainStore();
  const { getNestedValue, setNestedValue } = state.actions;
  const initialDate = getNestedValue(state, field);
  const parsedDate = initialDate ? new Date(initialDate.date) : "";
  const [day, setDay] = useState(parsedDate ? parsedDate.getDate() : "");
  const [month, setMonth] = useState(parsedDate ? parsedDate.getMonth() : "");
  const [year, setYear] = useState(parsedDate ? parsedDate.getFullYear() : "");
  const [days, setDays] = useState([]);

  // Effects
  useEffect(() => {
    console.log("1");
    let numberOfDays = 31;
    if (+month === 1) {
      numberOfDays =
        (+year % 4 === 0 && +year % 100 !== 0) || +year % 400 === 0 ? 29 : 28;
    } else if (MONTHS_WITH_30_DAYS.includes(+month)) {
      numberOfDays = 30;
    }
    const dayOptions = Array.from({ length: numberOfDays }, (_, i) => i + 1);
    setDays(dayOptions);
    if (day > numberOfDays) {
      setDay(String(numberOfDays));
    }
  }, [month, year, day]);
  useEffect(() => {
    if (+day && +month && +year) {
      const formattedDate = new Date(
        Date.UTC(+year, MONTHS.indexOf(+month), +day)
      ).toISOString();
      setNestedValue(field, { date: formattedDate, dateType: "DAY" });
    }
  }, [day, month, year, field, setNestedValue]);

  // Markups
  return (
    <>
      <div className="Custom-Label">
        <Text fontWeight="regular" variant="headingSm" as="p">
          {label}
        </Text>
      </div>
      <LegacyStack distribution="fillEvenly">
        <Select
          options={days.map((day) => ({
            label: String(day),
            value: String(day),
          }))}
          onChange={(value) => setDay(value)}
          value={day}
          placeholder="DD"
        />
        <Select
          options={MONTHS.map((month) => ({
            label: String(month + 1),
            value: String(month),
          }))}
          onChange={(value) => setMonth(value)}
          value={month}
          placeholder="MM"
        />
        <Select
          options={YEARS.map((year) => ({
            label: String(year),
            value: String(year),
          }))}
          onChange={(value) => setYear(value)}
          value={year}
          placeholder="YYYY"
        />
      </LegacyStack>
    </>
  );
};
