import React from "react";
import { FormLayout, Text } from "@shopify/polaris";
// Component imports
import { RepeaterInputFieldEmail } from "./RepeaterInputFieldEmail";
import { RepeaterInputFieldPhone } from "./RepeaterInputFieldPhone";

export const RepeaterInputField = ({ label, mainField, type }) => {
  // Markups
  let inputMarkup; // Variable to store the output/result of the switch statement
  switch (type) {
    case "email":
      inputMarkup = <RepeaterInputFieldEmail mainField={mainField} />;
      break;
    case "phone":
      inputMarkup = <RepeaterInputFieldPhone mainField={mainField} />;
      break;
    default:
      inputMarkup = null;
  }

  return (
    <FormLayout>
      <div className="Custom-Label">
        <Text fontWeight="regular" variant="headingSm" as="p">
          {label}
        </Text>
      </div>
      {inputMarkup}
    </FormLayout>
  );
};
