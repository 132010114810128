import React, { useEffect } from "react";
// Style imports
import "./App.css";
// Provider imports
import { TranslationProvider } from "./hooks/useTranslation";
// State imports
import { useTranslationStore } from "./stores/useTranslationStore";
// Page imports
import { Editor } from "./pages/Editor";
// Util imports
import loadTranslations from "./utils/translations/loadTranslations";

const App = () => {
  // State
  const { setTranslations, setLanguage, language } = useTranslationStore();
  // Effects
  useEffect(() => {
    async function fetchTranslations() {
      const translationData = await loadTranslations(language);
      setTranslations(translationData);
    }
    fetchTranslations();
  }, [language, setTranslations]);
  // Markups
  return (
    <TranslationProvider>
      <div className="App">
        <Editor />
      </div>
    </TranslationProvider>
  );
};

export default App;
