import React from "react";
import { Button, TextField, LegacyStack } from "@shopify/polaris";
import { DeleteMinor } from "@shopify/polaris-icons";
// Hook imports
import { useTranslation } from "../../hooks/useTranslation";
// State imports
import { useMainStore } from "../../stores/useMainStore";
// Component imports
import { RepeaterAddButton } from "./";

export const RepeaterInputFieldEmail = ({ mainField }) => {
  // State
  const translations = useTranslation();
  const state = useMainStore();
  const { actions: mainActions } = state;
  const { getNestedValue, setNestedValue, deleteRepeater } = mainActions;
  const repeaterValue = getNestedValue(state, mainField);

  // Handlers
  const addRepeater = () => {
    const allFieldsValid = repeaterValue.every((value) => isValidEmail(value));
    if (allFieldsValid) {
      setNestedValue(mainField, [...repeaterValue, ""]);
    }
  };

  // Validators
  function isValidEmail(email) {
    // Regular expression pattern for a basic email validation
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return pattern.test(email);
  }

  // Markups
  return (
    <>
      {repeaterValue.map((repeaterValueItem, index) => {
        return (
          <div key={`${mainField}-${index}`} className="repeater-stack">
            <LegacyStack wrap={false} alignment="leading" spacing="loose">
              <LegacyStack.Item fill>
                <TextField
                  value={repeaterValueItem}
                  onChange={(value) =>
                    setNestedValue(`${mainField}[${index}]`, value)
                  }
                />
              </LegacyStack.Item>
              {repeaterValue.length > 1 && (
                <Button
                  destructive
                  outline
                  icon={DeleteMinor}
                  accessibilityLabel={translations["global.remove"]}
                  onClick={() => deleteRepeater(mainField, index)}
                />
              )}
            </LegacyStack>
          </div>
        );
      })}
      <RepeaterAddButton
        label={translations["global.add"]}
        onClick={() => addRepeater()}
        disabled={!repeaterValue.every((value) => isValidEmail(value))}
      />
    </>
  );
};
