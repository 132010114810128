import create from "zustand";
import { debounce, set as nestedSet, get as nestedGet } from "lodash";

// Default value imports
import profileInit from "../utils/profileInit.json";

// Create debounced function for updating cvPreview
const debouncedSetCVPreview = debounce((set, state) => {
  set({ shouldRenderPreview: false });
  const cvPreview = generateCVPreview(state); // Assume generateCVPreview is a function that generates cvPreview based on the state.
  set({ cvPreview, shouldRenderPreview: true });
}, 500);

const generateCVPreview = (state) => {
  // NEW
  const { profile } = state;
  return { ...profile };
};

export const useMainStore = create((set, get) => ({
  profileInit: {
    ...profileInit,
  },
  profile: {
    language: "en",
    personalInformation: {
      firstName: "",
      lastName: "",
      personalMottoLine: null,
      personalDescription: `<p class="editor-paragraph"><br></p>`,
      dateOfBirth: null,
      sex: "",
      nationalities: [""],
      emails: [""],
      phones: [""],
    },
    preference: {
      id: "",
      profileId: "",
      profileStructure: [],
      dateFormat: "",
    },
    customSections: [],
  },
  expandedSection: "personalDetails",
  personalDetailsExpanded: true,
  experienceDetailsExpanded: false,
  educationDetailsExpanded: false,
  firstName: "",
  lastName: "",
  dateOfBirthDate: 1,
  dateOfBirthMonth: "January",
  dateOfBirthYear: "2000",
  nationality: "",
  gender: "",
  phone: "",
  email: "",
  addressStreet: "",
  addressCity: "",
  addressPostal: "",
  addressCountry: "",
  profileOld: ``,
  //   profile: `<ul class="editor-list-ul"><li value="1" class="editor-listitem"><span>asdfdsasdfasdffdsasdf</span></li></ul>`,
  workExperience: [],
  education: [],
  cvPreview: {},
  shouldRenderPreview: false,
  actions: {
    setValue: (field, value) => {
      set((state) => ({ ...state, [field]: value }));
      debouncedSetCVPreview(set, get());
    },
    setNestedValue: (fieldName, value) => {
      set((state) => {
        const newState = { ...state };
        nestedSet(newState, fieldName, value);
        return newState;
      });
      debouncedSetCVPreview(set, get());
    },
    getNestedValue: (obj, path) => {
      return nestedGet(obj, path);
    },
    deleteRepeater: (field, index) => {
      set((state) => {
        const newState = { ...state };
        const nestedField = state.actions.getNestedValue(newState, field);
        const updatedRepeater = [...nestedField];
        if (updatedRepeater.length > 1) {
          updatedRepeater.splice(index, 1);
          nestedSet(newState, field, updatedRepeater);
        }
        return newState;
      });
      debouncedSetCVPreview(set, get());
    },
    moveRepeater: (field, index, direction) => {
      set((state) => {
        const updatedRepeater = [...state[field]];
        const [movedItem] = updatedRepeater.splice(index, 1);
        updatedRepeater.splice(index + direction, 0, movedItem);
        return { ...state, [field]: updatedRepeater };
      });
      debouncedSetCVPreview(set, get());
    },
  },
}));
