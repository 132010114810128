import React from "react";
import { Collapsible, Page, Grid, LegacyCard, Select } from "@shopify/polaris";
// Hook imports
import { useTranslation } from "../hooks/useTranslation";
// State imports
import { useTranslationStore } from "../stores/useTranslationStore";
import { useMainStore } from "../stores/useMainStore";
// Component imports
import Preview from "../components/Preview";
import {
  General,
  PersonalDetails,
  ContactDetails,
  ExperienceDetails,
  EducationDetails,
} from "../components/form";

export const Editor = () => {
  // State
  const translations = useTranslation();
  const {
    shouldRenderPreview,
    expandedSection,
    actions: mainActions,
  } = useMainStore();
  const { setValue } = mainActions;

  const { language, setLanguage } = useTranslationStore();

  // Utils
  const prepareCollapsible = (sectionTitle) => {
    return {
      actions: [
        expandedSection !== sectionTitle
          ? {
              content: "Expand", // TODO: Translation
              onAction: () => setValue("expandedSection", sectionTitle),
            }
          : {},
      ],
      collapsibleProps: {
        open: expandedSection === sectionTitle,
        id: `${sectionTitle}-collapsible`,
        transition: {
          duration: "500ms",
          timingFunction: "ease-in-out",
        },
      },
    };
  };

  const personalDetailsCollapsible = prepareCollapsible("personalDetails");
  const contactDetailsCollapsible = prepareCollapsible("contactDetails");

  return (
    <Page fullWidth title="CV Maker">
      <Grid>
        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 5, xl: 5 }}>
          <LegacyCard sectioned>
            <Select
              label={translations["global.select-cv-language"]}
              options={[
                {
                  label: translations["global.dropDownLanguages.en"],
                  value: "en",
                },
                {
                  label: translations["global.dropDownLanguages.bg"],
                  value: "bg",
                },
                {
                  label: translations["global.dropDownLanguages.hr"],
                  value: "hr",
                },
                {
                  label: translations["global.dropDownLanguages.cs"],
                  value: "cs",
                },
                {
                  label: translations["global.dropDownLanguages.da"],
                  value: "da",
                },
                {
                  label: translations["global.dropDownLanguages.nl"],
                  value: "nl",
                },
                {
                  label: translations["global.dropDownLanguages.et"],
                  value: "et",
                },
                {
                  label: translations["global.dropDownLanguages.fi"],
                  value: "fi",
                },
                {
                  label: translations["global.dropDownLanguages.fr"],
                  value: "fr",
                },
                {
                  label: translations["global.dropDownLanguages.de"],
                  value: "de",
                },
                {
                  label: translations["global.dropDownLanguages.el"],
                  value: "el",
                },
                {
                  label: translations["global.dropDownLanguages.hu"],
                  value: "hu",
                },
                {
                  label: translations["global.dropDownLanguages.is"],
                  value: "is",
                },
                {
                  label: translations["global.dropDownLanguages.ga"],
                  value: "ga",
                },
                {
                  label: translations["global.dropDownLanguages.it"],
                  value: "it",
                },
                {
                  label: translations["global.dropDownLanguages.lv"],
                  value: "lv",
                },
                {
                  label: translations["global.dropDownLanguages.lt"],
                  value: "lt",
                },
                {
                  label: translations["global.dropDownLanguages.mk"],
                  value: "mk",
                },
                {
                  label: translations["global.dropDownLanguages.mt"],
                  value: "mt",
                },
                {
                  label: translations["global.dropDownLanguages.no"],
                  value: "no",
                },
                {
                  label: translations["global.dropDownLanguages.pl"],
                  value: "pl",
                },
                {
                  label: translations["global.dropDownLanguages.pt"],
                  value: "pt",
                },
                {
                  label: translations["global.dropDownLanguages.ro"],
                  value: "ro",
                },
                {
                  label: translations["global.dropDownLanguages.sr"],
                  value: "sr",
                },
                {
                  label: translations["global.dropDownLanguages.sk"],
                  value: "sk",
                },
                {
                  label: translations["global.dropDownLanguages.sl"],
                  value: "sl",
                },
                {
                  label: translations["global.dropDownLanguages.es"],
                  value: "es",
                },
                {
                  label: translations["global.dropDownLanguages.sv"],
                  value: "sv",
                },
                {
                  label: translations["global.dropDownLanguages.tr"],
                  value: "tr",
                },
                {
                  label: translations["global.dropDownLanguages.uk"],
                  value: "uk",
                },
                {
                  label: translations["global.dropDownLanguages.me"],
                  value: "me",
                },
              ]}
              onChange={(value) => {
                setLanguage(value);
              }}
              value={language}
            />
          </LegacyCard>
          <LegacyCard sectioned>
            <General />
          </LegacyCard>
          <LegacyCard
            title={
              translations[
                "cover-letter.wizard.step.edit.section.personal-info.name"
              ]
            }
            sectioned
            actions={personalDetailsCollapsible.actions}
          >
            <Collapsible {...personalDetailsCollapsible.collapsibleProps}>
              <PersonalDetails />
            </Collapsible>
          </LegacyCard>

          <LegacyCard
            title={
              translations["profile.personal-information.label.contactDetails"]
            }
            sectioned
            actions={contactDetailsCollapsible.actions}
          >
            <Collapsible {...contactDetailsCollapsible.collapsibleProps}>
              <ContactDetails />
            </Collapsible>
          </LegacyCard>
          {/* <LegacyCard
            title="Work experience"
            sectioned
            actions={[
              {
                content: experienceDetailsExpanded ? "Collapse" : "Expand",
                onAction: () =>
                  setValue(
                    "experienceDetailsExpanded",
                    !experienceDetailsExpanded
                  ),
              },
            ]}
          >
            <Collapsible
              open={experienceDetailsExpanded}
              id="experienceDetails-collapsible"
              transition={{
                duration: "500ms",
                timingFunction: "ease-in-out",
              }}
            >
              <ExperienceDetails />
            </Collapsible>
          </LegacyCard>
          <LegacyCard
            title="Education"
            sectioned
            actions={[
              {
                content: educationDetailsExpanded ? "Collapse" : "Expand",
                onAction: () =>
                  setValue(
                    "educationDetailsExpanded",
                    !educationDetailsExpanded
                  ),
              },
            ]}
          >
            <Collapsible
              open={educationDetailsExpanded}
              id="educationDetails-collapsible"
              transition={{
                duration: "500ms",
                timingFunction: "ease-in-out",
              }}
            >
              <EducationDetails />
            </Collapsible>
          </LegacyCard> */}
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 7, xl: 7 }}>
          <LegacyCard title="Your CV" sectioned>
            {shouldRenderPreview && <Preview />}
          </LegacyCard>
        </Grid.Cell>
      </Grid>
    </Page>
  );
};
