// loadTranslations.js
async function loadTranslations(language) {
  switch (language) {
    case "en":
      return import("./translations-en.json");
    case "lv":
      return import("./translations-lv.json");
    case "bg":
      return import("./translations-bg.json");
    case "hr":
      return import("./translations-hr.json");
    case "cs":
      return import("./translations-cs.json");
    case "da":
      return import("./translations-da.json");
    case "nl":
      return import("./translations-nl.json");
    case "et":
      return import("./translations-et.json");
    case "fi":
      return import("./translations-fi.json");
    case "fr":
      return import("./translations-fr.json");
    case "de":
      return import("./translations-de.json");
    case "el":
      return import("./translations-el.json");
    case "hu":
      return import("./translations-hu.json");
    case "is":
      return import("./translations-is.json");
    case "ga":
      return import("./translations-ga.json");
    case "it":
      return import("./translations-it.json");
    case "lt":
      return import("./translations-lt.json");
    case "mk":
      return import("./translations-mk.json");
    case "mt":
      return import("./translations-mt.json");
    case "no":
      return import("./translations-no.json");
    case "pl":
      return import("./translations-pl.json");
    case "pt":
      return import("./translations-pt.json");
    case "ro":
      return import("./translations-ro.json");
    case "sr":
      return import("./translations-sr.json");
    case "sk":
      return import("./translations-sk.json");
    case "sl":
      return import("./translations-sl.json");
    case "es":
      return import("./translations-es.json");
    case "sv":
      return import("./translations-sv.json");
    case "tr":
      return import("./translations-tr.json");
    case "uk":
      return import("./translations-uk.json");
    case "me":
      return import("./translations-me.json");
    default:
      return import("./translations-en.json");
  }
}

export default loadTranslations;
