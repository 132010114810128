// TranslationContext.js
import React, { createContext, useContext } from "react";
import { useTranslationStore } from "../stores/useTranslationStore";

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const translations = useTranslationStore((state) => state.translations);
  return (
    <TranslationContext.Provider value={translations}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  return useContext(TranslationContext);
};
