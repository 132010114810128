import React from "react";
import { FormLayout, TextField, LegacyStack, Select } from "@shopify/polaris";
// Hook imports
import { useTranslation } from "../../hooks/useTranslation";
// State imports
import { useMainStore } from "../../stores/useMainStore";
// Component imports
import {
  AutocompleteNationalityField,
  DatePickerField,
  LexiField,
} from "../fields";
// Util imports
import sexes from "../../utils/codifications/sexes.json";

export const PersonalDetails = () => {
  // State
  const translations = useTranslation();
  const { profile, actions: mainActions } = useMainStore();
  const { setNestedValue } = mainActions;
  return (
    <FormLayout>
      <LegacyStack distribution="fillEvenly">
        <TextField
          label={
            translations["profile.personal-information.edit-form.firstName"]
          }
          value={profile.personalInformation.firstName}
          onChange={(value) =>
            setNestedValue("profile.personalInformation.firstName", value)
          }
        />
        <TextField
          label={
            translations["profile.personal-information.edit-form.lastName"]
          }
          value={profile.personalInformation.lastName}
          onChange={(value) =>
            setNestedValue("profile.personalInformation.lastName", value)
          }
        />
      </LegacyStack>
      <LexiField
        label={
          translations[
            "profile.personal-information.edit-form.personalDescription"
          ]
        }
        field="profile.personalInformation.personalDescription"
        value={profile.personalInformation.personalDescription}
      />
      <LegacyStack distribution="fillEvenly">
        <DatePickerField
          label={translations["profile.personal-information.label.dateOfBirth"]}
          field="profile.personalInformation.dateOfBirth"
        />
        <Select
          label={translations["profile.personal-information.label.sex"]}
          options={sexes.map((sex) => ({
            label: translations[`profile.personal-information.sex.${sex}`],
            value: sex,
          }))}
          onChange={(value) =>
            setNestedValue("profile.personalInformation.sex", value)
          }
          value={profile.personalInformation.sex}
          placeholder={translations["global.select-from-list"]}
        />
      </LegacyStack>
      <AutocompleteNationalityField
        label={translations["profile.personal-information.label.nationality"]}
        field="profile.personalInformation.nationalities"
      />
      {/* <TextField
        label="Nationality"
        value={nationality}
        onChange={(value) => setValue("nationality", value)}
      />
      <LegacyStack distribution="fillEvenly">
        <TextField
          label="Phone number"
          value={phone}
          onChange={(value) => setValue("phone", value)}
        />
        <TextField
          label="Email"
          value={email}
          onChange={(value) => setValue("email", value)}
        />
      </LegacyStack>
      <Text variant="headingSm" as="h6" alignment="start">
        Address
      </Text>
      <LegacyStack distribution="fillEvenly">
        <TextField
          label="Street, number"
          value={addressStreet}
          onChange={(value) => setValue("addressStreet", value)}
        />
        <TextField
          label="City"
          value={addressCity}
          onChange={(value) => setValue("addressCity", value)}
        />
      </LegacyStack>
      <LegacyStack distribution="fillEvenly">
        <TextField
          label="Postal"
          value={addressPostal}
          onChange={(value) => setValue("addressPostal", value)}
        />
        <TextField
          label="Country"
          value={addressCountry}
          onChange={(value) => setValue("addressCountry", value)}
        />
      </LegacyStack> */}
    </FormLayout>
  );
};
