import { Autocomplete, Icon, HorizontalStack, Select } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { useState, useCallback } from "react";
// Hook imports
import { useTranslation } from "../../hooks/useTranslation";
// State imports
import { useMainStore } from "../../stores/useMainStore";
// Component imports
import { RepeaterAddButton } from "./";
// Util imports
import telephonePrefixes from "../../utils/codifications/telephonePrefix.json";
import telephoneTypes from "../../utils/codifications/telephoneTypes.json";

export const RepeaterInputFieldPhone = ({ mainField }) => {
  // State
  const translations = useTranslation();
  const state = useMainStore();
  const { actions: mainActions } = state;
  const { getNestedValue, setNestedValue, deleteRepeater } = mainActions;
  const repeaterValue = getNestedValue(state, mainField);
  console.log(repeaterValue);
  const deselectedTypeOptions = telephoneTypes.map((telephoneType) => {
    return {
      value: telephoneType,
      label: `${
        translations[
          `profile.personal-information.phone-number-type.${telephoneType}`
        ]
      }`,
    };
  });
  const deselectedPrefixOptions = Object.entries(telephonePrefixes).map(
    ([countryCode, prefix]) => {
      return {
        key: countryCode,
        value: `+${prefix.join(", ")}`,
        label: `${translations[`global.country.${countryCode}`]} (+${prefix})`,
      };
    }
  );

  const [selectedPrefixOptions, setSelectedPrefixOptions] = useState([]);
  const [inputPrefixValue, setInputPrefixValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [prefixOptions, setPrefixOptions] = useState(deselectedPrefixOptions);
  const [typeOptions, setTypeOptions] = useState(deselectedTypeOptions);

  // Handlers
  const addRepeater = () => {
    const allFieldsValid = repeaterValue.every((value) => isValidPhone(value));
    if (allFieldsValid) {
      setNestedValue(mainField, [
        ...repeaterValue,
        {
          phonePrefix: null,
          phoneNumber: "",
          customPhoneNumberType: "",
          phoneNumberType: "",
        },
      ]);
    }
  };
  const updatePrefix = useCallback(
    (value) => {
      setInputPrefixValue(value);
      if (value === "") {
        setPrefixOptions(deselectedPrefixOptions);
        return;
      }
      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedPrefixOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setPrefixOptions(resultOptions);
    },
    [deselectedPrefixOptions]
  );
  const updatePrefixSelection = useCallback(
    (selected) => {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = prefixOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption && matchedOption.value;
      });
      console.log("selectedValue");
      console.log(selectedValue);
      setSelectedPrefixOptions(selected);
      setInputPrefixValue(selectedValue[0] || "");
    },
    [prefixOptions]
  );
  const updateTypeSelection = useCallback(
    (selected) => {
      setTypeValue(selected);
      setTypeOptions(
        typeOptions.filter((typeOption) => typeOption !== selected)
      );
    },
    [typeOptions]
  );

  // Validators
  function isValidPhone(phone) {
    return (
      (phone.phoneNumberType && phone.phonePrefix && phone.phoneNumber) ||
      phone.customPhoneNumberType
    );
  }

  // Markups
  return (
    <>
      {repeaterValue.map((repeaterValueItem, index) => {
        return (
          <HorizontalStack key={`${mainField}-${index}`}>
            <div className="CV-phone-type">
              <Select
                options={typeOptions}
                onChange={(value) =>
                  setNestedValue(
                    `${mainField}[${index}].phoneNumberType`,
                    value
                  )
                }
                value={repeaterValueItem.phoneNumberType}
                placeholder={translations["global.dropdown-select"]}
              />
            </div>
            <Autocomplete
              options={prefixOptions}
              selected={selectedPrefixOptions}
              onSelect={(value) => {
                console.log(value);
                setNestedValue(`${mainField}[${index}].phonePrefix`, value);
              }}
              textField={
                <Autocomplete.TextField
                  onChange={(value) => {
                    console.log(value);
                    setNestedValue(`${mainField}[${index}].phonePrefix`, value);
                  }}
                  value={repeaterValueItem.phonePrefix}
                  prefix={<Icon source={SearchMinor} color="base" />}
                  autoComplete="off"
                  placeholder={translations["global.dropdown-select"]}
                />
              }
            />
          </HorizontalStack>
        );
      })}
      <RepeaterAddButton
        label={translations["global.add"]}
        onClick={() => addRepeater()}
        disabled={!repeaterValue.every((value) => isValidPhone(value))}
      />
    </>
  );
};
