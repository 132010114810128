import React from "react";
import { Text, View, Link } from "@react-pdf/renderer";

let olCounter = 1;

const parseHtmlToPdfComponents = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const elements = Array.from(doc.body.childNodes);

  const getTextAlignStyle = (element) => {
    return element.style.textAlign
      ? { textAlign: element.style.textAlign }
      : {};
  };

  const parseElement = (element) => {
    if (element.nodeType === 3) {
      // Text node
      return element.nodeValue;
    }
    const children = Array.from(element.childNodes).map(parseElement);

    const textAlignStyle = getTextAlignStyle(element);

    if (element.tagName === "P" || element.tagName === "SPAN") {
      return (
        <Text style={{ margin: 2, ...textAlignStyle }}>
          {Array.from(element.childNodes).map(parseElement)}
        </Text>
      );
    }

    if (element.tagName === "STRONG" || element.tagName === "B") {
      return (
        <Text style={{ fontWeight: "semibold", ...textAlignStyle }}>
          {Array.from(element.childNodes).map(parseElement)}
        </Text>
      );
    }

    if (element.tagName === "EM" || element.tagName === "I") {
      return (
        <Text style={{ fontStyle: "italic", ...textAlignStyle }}>
          {Array.from(element.childNodes).map(parseElement)}
        </Text>
      );
    }

    if (element.tagName === "UL") {
      olCounter = 1; // Reset the counter when encountering a new list
      return (
        <View style={{ marginLeft: 10 }}>
          {children.map((child, index) => (
            <View style={{ flexDirection: "row", marginBottom: 5 }}>
              <Text style={{ marginRight: 5 }}>•</Text>
              <Text>{child}</Text>
            </View>
          ))}
        </View>
      );
    }

    if (element.tagName === "OL") {
      olCounter = 1; // Reset the counter when encountering a new list
      return (
        <View style={{ marginLeft: 10 }}>
          {children.map((child, index) => (
            <View style={{ flexDirection: "row", marginBottom: 5 }}>
              <Text style={{ marginRight: 5 }}>{olCounter++}.</Text>
              <Text>{child}</Text>
            </View>
          ))}
        </View>
      );
    }

    if (element.tagName === "LI") {
      return (
        <View style={{ flexDirection: "row", marginBottom: 5 }}>
          <Text>{children}</Text>
        </View>
      );
    }

    if (element.tagName === "BR") {
      return "\n";
    }

    if (element.tagName === "S") {
      return (
        <View style={{ flexDirection: "column", alignItems: "center" }}>
          <Text style={{ textDecoration: "line-through" }}>{children}</Text>
        </View>
      );
    }

    if (element.tagName === "A") {
      const href = element.getAttribute("href");
      return (
        <Link src={href} style={{ color: "blue", textDecoration: "underline" }}>
          {children}
        </Link>
      );
    }

    return null;
  };

  return elements.map(parseElement);
};

export default parseHtmlToPdfComponents;
