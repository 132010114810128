import React from "react";
import { View } from "@react-pdf/renderer";
import parseHtmlToPdfComponents from "./parseHtmlToPdfComponents";

const PreviewRichText = ({ html }) => {
  const pdfComponents = html ? parseHtmlToPdfComponents(html) : "";
  return <View>{pdfComponents}</View>;
};

export default PreviewRichText;
