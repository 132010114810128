// Util imports
import { MONTHS } from "./constants";

export const monthNameToNumber = (monthName) => {
  const monthNumber = MONTHS.indexOf(monthName) + 1;
  return monthNumber < 10 ? `0${monthNumber}` : monthNumber;
};

export const prepareRepeaterFields = (repeaterValue) => {
  const fields = { ...repeaterValue };
  fields["from"] = formatDate(
    fields.fromDate,
    fields.fromMonth,
    fields.fromYear
  );
  fields["to"] = formatDate(fields.toDate, fields.toMonth, fields.toYear);
  fields["dateAndLocation"] = formatDateAndLocation(fields);
  return fields;
};

export const formatDate = (rawDate, rawMonth, rawYear) => {
  const date = rawDate < 10 ? `0${rawDate}` : rawDate;
  const month = monthNameToNumber(rawMonth);
  const year = rawYear;
  if (date && month && year) {
    return `${date}/${month}/${year}`;
  }
  return null;
};

export const formatDateFromDateString = (dateString) => {
  const dateObj = new Date(dateString);
  const day = dateObj.getUTCDate().toString().padStart(2, "0"); // Get day and pad with 0 if it's a single digit
  const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0"); // Get month (0 indexed so +1), and pad
  const year = dateObj.getUTCFullYear();

  return `${day}-${month}-${year}`; // Format like "DD-MM-YYYY"
};

export const formatAddress = (address) => {
  const { addressStreet, addressPostal, addressCity, addressCountry } = address;
  // Create an array with the address components
  const addressComponents = [
    addressStreet,
    addressPostal,
    addressCity,
    addressCountry,
  ];
  // Filter out any undefined or empty string values
  const filteredComponents = addressComponents.filter(Boolean);
  // Join the remaining components with a comma and a space
  return filteredComponents.join(", ");
};

export const formatDateAndLocation = ({ from, to, current, city, country }) => {
  let dateString = "";
  let locationString = "";
  // Format the date string
  if (from) {
    dateString += from;
  }
  if (current) {
    dateString += " – Current";
  } else if (to) {
    dateString += ` – ${to}`;
  }
  // Format the location string
  if (city) {
    locationString += city;
  }
  if (country) {
    locationString += locationString ? `, ${country}` : country;
  }
  // Combine date and location strings
  const finalString = `${dateString} ${locationString}`;
  return finalString.trim(); // Remove any extra spaces at the beginning or end
};
