import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
// Util imports
import { formatDateFromDateString, formatAddress } from "../utils/helpers";
// State imports
import { useMainStore } from "../stores/useMainStore";
// Component imports
import PreviewRichText from "./PreviewRichText";
import PreviewExperience from "./PreviewExperience";
import PreviewEducation from "./PreviewEducation";

// Font size reference
//   --font-xs: 0.8rem; 10.64px
//   --font-sm: 0.9rem; 11.97px
//   --font-md: 1rem; 13.3px
//   --font-lg: 1.4rem; 18.62px

// Create styles
Font.register({
  family: "Open Sans",
  fonts: [
    { src: "/fonts/OpenSans-Regular.ttf" }, // regular weight
    { src: "/fonts/OpenSans-SemiBold.ttf", fontWeight: "semibold" }, // semibold weight
    { src: "/fonts/OpenSans-Bold.ttf", fontWeight: "bold" }, // bold weight
    { src: "/fonts/OpenSans-ExtraBold.ttf", fontWeight: "extrabold" }, // extrabold weight
    { src: "/fonts/OpenSans-Italic.ttf", fontStyle: "italic" }, // italic style
    {
      src: "/fonts/OpenSans-SemiBoldItalic.ttf",
      fontWeight: "semibold",
      fontStyle: "italic",
    }, // bold and italic
  ],
});

const styles = StyleSheet.create({
  body: {
    fontFamily: "Open Sans",
    paddingBottom: 65,
    fontSize: "13.3px",
  },
  paragraph: {
    marginBottom: 10,
  },
  bold: {
    fontWeight: "bold",
  },
  italic: {
    fontStyle: "italic",
  },
  sectionContainer: {
    paddingLeft: "6mm",
    paddingRight: "11mm",
    marginBottom: "3.44mm",
  },
  section: {
    paddingLeft: "6mm",
    paddingRight: "11mm",
  },
  sectionTitle: {
    fontSize: "13.3px",
    fontWeight: "extrabold",
    paddingBottom: "1mm",
    marginBottom: "3.44mm",
    borderBottom: "2.5px solid #979797",
    textTransform: "uppercase",
  },
  sectionRichContent: {
    fontSize: "11.97px",
  },
  sectionBullet: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    left: 0,
    top: "4mm",
    width: "2mm",
    height: "2mm",
    borderRadius: "100%",
    backgroundColor: "#979797",
  },
  semiBold: {
    fontWeight: "semibold",
  },
  separator: {
    marginTop: 0,
    marginBottom: "3.44mm",
    borderTop: "2px solid #979797",
    borderBottom: "none",
  },
  verticalSeparator: {
    display: "block",
    paddingRight: "2mm",
    borderRight: "0.8pt solid #979797",
    height: "11.97px",
  },
  personalDetails: {
    padding: "6mm 11mm 4mm 6mm",
    paddingTop: "20mm",
    backgroundColor: "#f5f5f5",
    marginBottom: "3.44mm",
  },
  title: {
    fontWeight: "extrabold",
    fontSize: "18.62px",
    color: "#565656",
    marginBottom: "3mm",
  },
  details: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: 0,
    paddingLeft: 0,
    wordBreak: "keep-all",
    overflowWrap: "normal",
    fontSize: "11.97px",
  },
  detailsItemContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    marginBottom: "4mm",
    marginRight: "1.8mm",
  },
  detailsItem: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "1mm",
  },
  subtitle: {
    fontSize: "18.62px",
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

// Create Document Component
export default function Preview() {
  const { cvPreview } = useMainStore();
  console.log(cvPreview.personalInformation.dateOfBirth);
  const fields = [
    {
      label: "Date of birth",
      value: cvPreview.personalInformation.dateOfBirth
        ? formatDateFromDateString(
            cvPreview.personalInformation.dateOfBirth.date
          )
        : null,
      // value: formatDate(
      //   cvPreview.dateOfBirthDate,
      //   cvPreview.dateOfBirthMonth,
      //   cvPreview.dateOfBirthYear
      // ),
    },
    {
      label: "Nationalities",
      value: cvPreview.personalInformation.nationalities.join(", "),
    },
    { label: "Gender", value: cvPreview.personalInformation.sex },
    // { label: "Phone", value: cvPreview.phone }, TODO: Move preview
    // { label: "Email", value: cvPreview.email }, TODO: Move preview
    {
      label: "Address",
      value: formatAddress(cvPreview),
    },
  ];
  const filteredFields = fields.filter((field) => field.value);
  console.log(cvPreview);

  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.body}>
          <View style={styles.personalDetails}>
            <View>
              <Text style={styles.title}>
                {cvPreview.personalInformation.firstName}{" "}
                {cvPreview.personalInformation.lastName}
              </Text>
            </View>
            <View style={styles.separator}></View>
            <View style={styles.details}>
              {filteredFields.map((field, index) => {
                console.log("Hi there!");
                console.log(field);
                if (field.value && field.value.date) {
                  field.value = field.value.date; // TODO: Make or move to more robust
                }
                return (
                  <View key={field.label} style={styles.detailsItemContainer}>
                    <View style={styles.detailsItem}>
                      <Text style={styles.semiBold}>{field.label}:</Text>
                      <Text>{field.value}</Text>
                    </View>
                    {index < filteredFields.length - 1 && (
                      <View style={styles.verticalSeparator} />
                    )}
                  </View>
                );
              })}
            </View>
          </View>
          {cvPreview.personalInformation.personalDescription &&
            cvPreview.personalInformation.personalDescription !==
              `<p class="editor-paragraph"><br></p>` && (
              <View style={styles.sectionContainer}>
                <View style={styles.section}>
                  <View style={styles.sectionBullet}></View>
                  <Text style={styles.sectionTitle}>About me</Text>
                  <View style={styles.sectionRichContent}>
                    <PreviewRichText
                      html={cvPreview.personalInformation.personalDescription}
                    />
                  </View>
                </View>
              </View>
            )}
          {/* {cvPreview.workExperience && cvPreview.workExperience.length && (
            <PreviewExperience cvPreview={cvPreview} styles={styles} />
          )}
          {cvPreview.education && cvPreview.education.length && (
            <PreviewEducation cvPreview={cvPreview} styles={styles} />
          )} */}
          {/* TODO: Move variables */}
        </Page>
      </Document>
    </PDFViewer>
  );
}
