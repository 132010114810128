import { LegacyStack, Tag, Autocomplete } from "@shopify/polaris";
import { useState, useCallback, useEffect } from "react";
// Hook imports
import { useTranslation } from "../../hooks/useTranslation";
// Util imports
import countries from "../../utils/codifications/countries.json";

export const AutocompleteNationalityField = ({ label, field }) => {
  // State
  const translations = useTranslation();
  const deselectedOptions = countries.map((country) => {
    return {
      value: country.code,
      label: translations[`global.nationality.${country.code}`],
    };
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptions(deselectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translations]);

  // Handlers
  const updateText = useCallback(
    (value) => {
      setInputValue(value);
      if (value === "") {
        setOptions(deselectedOptions);
        return;
      }
      const filterRegex = new RegExp(value, "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const removeTag = useCallback(
    (tag) => () => {
      const options = [...selectedOptions];
      options.splice(options.indexOf(tag), 1);
      setSelectedOptions(options);
    },
    [selectedOptions]
  );

  const verticalContentMarkup =
    selectedOptions.length > 0 ? (
      <LegacyStack spacing="extraTight" alignment="center">
        {selectedOptions.map((option) => {
          const tag = deselectedOptions.find(
            (deselectedOption) => option === deselectedOption.value
          );
          return (
            <Tag key={`option${option}`} onRemove={removeTag(option)}>
              {tag.label}
            </Tag>
          );
        })}
      </LegacyStack>
    ) : null;

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label={label}
      value={inputValue}
      placeholder=""
      verticalContent={verticalContentMarkup}
      autoComplete="off"
    />
  );

  return (
    <Autocomplete
      allowMultiple
      options={options}
      selected={selectedOptions}
      textField={textField}
      onSelect={(value) => {
        setSelectedOptions(value);
        updateText("");
      }}
      listTitle={translations["global.dropdown-select"]}
    />
  );
};
